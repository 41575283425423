
* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", Arial, sans-serif;
}

body {
  margin: 0;
  background-color: #ffffff;
}
